import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { collection, getDocs, addDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { doc, deleteDoc } from "firebase/firestore";

/*
type User = {
    id: string,
    email: string,
    first_name: string,
    last_name: string,
    avatar: string
}*/
/*
export const getLeadsContent = createAsyncThunk("/leads/content", async () => {
  const response = await axios.get("/api/users?page=2", {});
  console.log(response.data);
  return response.data;
});
*/
/*
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    console.log(doc.id, " => ", doc.data());
    return doc.data();
  });*/

export const getLeadsContent = createAsyncThunk("/leads/content", async () => {
  const querySnapshot = await getDocs(collection(db, "users"));

  const dataArray = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  console.log(dataArray);
  return dataArray;
});

export const leadsSlice = createSlice({
  name: "leads",
  initialState: {
    isLoading: false,
    leads: [],
  },
  reducers: {
    addNewLead: (state, action) => {
      let { newLeadObj } = action.payload;
      addDoc(collection(db, "users"), {
        email: newLeadObj.email,
        first_name: newLeadObj.first_name,
        last_name: newLeadObj.last_name,
        avatar: "https://reqres.in/img/faces/1-image.jpg",
      });
      state.leads = [...state.leads, newLeadObj];
    },

    deleteLead: (state, action) => {
      console.log(action.payload.index[0]);
      console.log(action.payload.index[1]);
      let indek = action.payload.index[0];
      let k = action.payload.index[1];
      console.log(indek);
      deleteDoc(doc(db, "users", indek));

      state.leads.splice(k, 1);
    },
  },

  extraReducers: {
    [getLeadsContent.pending]: (state) => {
      state.isLoading = true;
    },
    [getLeadsContent.fulfilled]: (state, action) => {
      state.leads = action.payload;
      state.isLoading = false;
    },
    [getLeadsContent.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { addNewLead, deleteLead } = leadsSlice.actions;

export default leadsSlice.reducer;
