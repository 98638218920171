import React, { lazy, useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { SkeletonTheme } from "react-loading-skeleton";
import { auth } from "./firebase"; // Import Firebase auth
import SuspenseContent from "./containers/SuspenseContent";
// Importing pages
const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Form = lazy(() => import("./pages/Form"));
const Register = lazy(() => import("./pages/Register"));
const Approvals = lazy(() => import("./pages/protected/Approvals"));

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Initialize Firebase Authentication
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      setUser(authUser);
      setLoading(false);
    });

    return () => {
      // Unsubscribe from Firebase auth state changes on unmount
      unsubscribe();
    };
  }, []);

  if (loading) {
    // You can display a loading indicator here while Firebase authentication is being checked
    return <SuspenseContent/>
  }

  return (
    <>
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <Router>
          <Routes>
          <Route path="/app/approvals/:caseID" element={user ? <Approvals /> : <Navigate to="/login" />} />


            <Route
              path="/login"
              element={user ? <Navigate to="/form" replace /> : <Login />}
            />

            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route
              path="/form"
              element={user ? <Form /> : <Navigate to="/login" />}
            />
            <Route
              path="/register"
              element={user ? <Register /> : <Navigate to="/login" />}
            />

            {/* Place new routes over this */}
            <Route
              path="/app/*"
              element={user ? <Layout /> : <Navigate to="/login" />}
            />

            <Route
              path="*"
              element={<Navigate to={user ? "/form" : "/login"} replace />}
            />
          </Routes>
        </Router>
      </SkeletonTheme>
    </>
  );
}

export default App;
