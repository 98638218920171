import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCZMHjvF-tsWKNzcN9ikLT7CkGIaT5kAv8",
  authDomain: "beaming-age-385416.firebaseapp.com",
  projectId: "beaming-age-385416",
  storageBucket: "beaming-age-385416.appspot.com",
  messagingSenderId: "604164277741",
  appId: "1:604164277741:web:8a2b5526b4e69e59d953e4",
};

const app = initializeApp(firebaseConfig);
//const db = getFirestore(app);

export const auth = getAuth(app);
export const db = getFirestore(app);
