import { ScaleLoader } from "react-spinners";

function SuspenseContent() {
  // bg-black bg-opacity-30
  return (
    <div class="fixed inset-0 flex items-center justify-center">
  <div class="absolute z-10 flex items-center justify-center w-full h-full">
    <ScaleLoader color="#641ae6" />
  </div>
</div>

  );
}

export default SuspenseContent;
